import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import project from '../../assets/images/projects/mah.jpg'
import project1 from '../../assets/images/projects/patac1.jpg'
import project2 from '../../assets/images/projects/patac2.jpg'
import project3 from '../../assets/images/projects/patac3.jpg'
import {Link} from 'gatsby'
import Lightbox from 'react-image-lightbox'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Mozambico Affidato Helpcode" 
                homePageText="Monitoring and evaluation" 
                homePageUrl="/social-balance-monitoring-evaluation" 
                activePageText="Mozambico Affidato Helpcode" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://helpcode.org" target="_blank" rel="noreferrer">Helpcode Italia</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Mozambico
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                        Dewco + Wordpress
                        </li>
                    
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
                        AICS
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        2 years 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status:</span>
                        Completed
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-globe'></i>
                        </div>
                        <span>Project link:</span>
                         <a href="https://svilupporuralemozambico.helpcode.org/"> Sviluppo rurale Mozambico</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={project} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Monitoring and evaluation</span>
                            <h3>Il progetto</h3>
                            <p>In 2019 Gnucoop created a platform aimed at collecting, analyzing and visualizing monitoring data and communicating the process of implementing activities and achieving results for the project “Rural development and strengthening the resilience of communities in Mozambique”, implemented by Helpcode.  
In the first phase of the out intervention, we provided the project our platform DEWCO to collect project data.  
In  the meantime we have developed the design of the web site where the data and the project Indicators will be displayed and available for everyone.
</p>              
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

const images = [
    (project1),
    (project2),
    (project3),
];

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <section>
            <div className="gallery-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(false); 
                                        setPhotoIndex(0);}
                                    }
                                >
                                    <img src={project1} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(1);}
                                    }
                                >
                                    <img src={project2} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(2);}
                                    }
                                >
                                    <img src={project3} alt="event" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            </section>
    );
}
